<template>
    <div class="text-center">
        <v-dialog
            v-model="locked"
            width="600"
            :persistent="locked"
            overlay-opacity="0.76"            
        >
            <v-card>
                <v-card-title
                    class="headline w-header-unlock"
                    primary-title
                >                    
                    <span>Desbloquear Pantalla</span>
                    <v-spacer></v-spacer>
                    <v-icon dark>fas fa-lock-open</v-icon>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row justify="center">
                            <v-col cols="8" class="pt-1 pb-1">
                                <span class="label-pin">INGRESA TU PIN (4 DÍGITOS)</span>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="2" class="pt-1">
                                <v-text-field 
                                    v-model="digit_a" 
                                    ref="digitA" 
                                    type="password"
                                    outlined 
                                    @input="handleInputDigitA" 
                                    maxlength="1" 
                                    hide-details
                                    height="60"
                                    :class="class_pin">
                                </v-text-field>
                            </v-col>
                            <v-col cols="2" class="pt-1">
                                <v-text-field 
                                    v-model="digit_b" 
                                    ref="digitB" 
                                    type="password"
                                    outlined 
                                    @input="handleInputDigitB" 
                                    maxlength="1" 
                                    hide-details
                                    height="60"
                                    :class="class_pin">
                                </v-text-field>
                            </v-col>
                            <v-col cols="2" class="pt-1">
                                <v-text-field 
                                    v-model="digit_c" 
                                    ref="digitC" 
                                    type="password"
                                    outlined 
                                    @input="handleInputDigitC" 
                                    maxlength="1" 
                                    hide-details
                                    height="60"
                                    :class="class_pin">
                                </v-text-field>
                            </v-col>
                            <v-col cols="2" class="pt-1">
                                <v-text-field 
                                    v-model="digit_d" 
                                    ref="digitD" 
                                    outlined 
                                    @input="handleInputDigitD" 
                                    maxlength="1" 
                                    hide-details
                                    height="60"
                                    :class="class_pin">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="2">
                                <v-btn fab @click="handlePressDigit(1)" class="btn-pin">
                                    1
                                </v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-btn fab @click="handlePressDigit(2)" class="btn-pin">
                                    2
                                </v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-btn fab @click="handlePressDigit(3)" class="btn-pin">
                                    3
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="2">
                                <v-btn fab @click="handlePressDigit(4)" class="btn-pin">
                                    4
                                </v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-btn fab @click="handlePressDigit(5)" class="btn-pin">
                                    5
                                </v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-btn fab @click="handlePressDigit(6)" class="btn-pin">
                                    6
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="2">
                                <v-btn fab @click="handlePressDigit(7)" class="btn-pin">
                                    7
                                </v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-btn fab @click="handlePressDigit(8)" class="btn-pin">
                                    8
                                </v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-btn fab @click="handlePressDigit(9)" class="btn-pin">
                                    9
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="2">
                                
                            </v-col>
                            <v-col cols="2">
                                <v-btn fab @click="handlePressDigit('0')" class="btn-pin">
                                    0
                                </v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-btn fab @click="handleClearPin" outlined color="blue">
                                    CLEAR
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snackbar"            
            color="error"
            :timeout="3000"
            :top="'top'"
        >
            PIN INVÁLIDO
            <v-btn
            dark
            text
            @click="snackbar = false"
            >
                Cerrar
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
    name: 'Locked',
    data() {
        return {
            snackbar: false,
            digit_a: null,
            digit_b: null,
            digit_c: null,
            digit_d: null,
            pin: null,
            input_pin: 'input_pin',
            hasErrorPin: false,
            class_pin: [
                'input-pin'
            ]
        }
    },
    computed: {
        ...mapState('authentication', [
            'locked',
            'pin_unlock'
        ])
    },
    methods: {
        ...mapActions('authentication', [
            'run_set_locked'
        ]),
        show(){
            this.handleClearPin();
        },
        handleClearPin(){
            this.digit_a = null;
            this.digit_b = null;
            this.digit_c = null;
            this.digit_d = null;
            this.pin = null;
        },
        handleInputDigitA(value){
            this.digit_a = value;
            this.joinDigitsPin();
            this.$refs.digitB.focus();
        },
        handleInputDigitB(value){
            this.digit_b = value;
            this.joinDigitsPin();
            this.$refs.digitC.focus();
        },
        handleInputDigitC(value){
            this.digit_c = value;
            this.joinDigitsPin();
            this.$refs.digitD.focus();
        },
        handleInputDigitD(value){
            this.digit_d = value;
            this.joinDigitsPin();
        },
        handlePressDigit(digit){
            if (this.digit_a == null || this.digit_a == '') {
                this.digit_a = digit;
            } else if (this.digit_b == null || this.digit_b == '') {
                this.digit_b = digit;
            } else if (this.digit_c == null || this.digit_c == '') {
                this.digit_c = digit;
            } else if (this.digit_d == null || this.digit_d == '') {
                this.digit_d = digit;
            }
            this.joinDigitsPin();
        },
        joinDigitsPin(){
            this.pin = '';
            if (this.digit_a) {
                this.pin += this.digit_a.toString();
            }
            if (this.digit_b) {
                this.pin += this.digit_b.toString();
            }
            if (this.digit_c) {
                this.pin += this.digit_c.toString();
            }
            if (this.digit_d) {
                this.pin += this.digit_d.toString();
            }
            if (this.pin.length == 4) {
                this.verifyPin();
            }
        },
        verifyPin(){
            if (this.pin_unlock == this.pin) {
                this.run_set_locked(false);
            } else {
                this.snackbar = true;
                this.handleClearPin();
                this.class_pin.push('animated wobble');
                setTimeout(this.deleteClassErrorPin, 1000);
            }
        },
        deleteClassErrorPin() {
            this.class_pin.splice(1, 1);
        }
    },
    mounted() {
        this.handleClearPin();
    }
}
</script>

<style scoped>
.w-navigation{
    height: 94% !important;
    background-color: #eef1f5 !important;
}

.w-header-unlock{
    background-color: #023145;
    color: #ffffff;
}

.label-pin{
    color: #023145;
    font-weight: 600;
}

.btn-pin{
    font-size: 1.3rem;
}

.input-pin >>> .v-text-field__slot input{
    font-size: 2rem !important;
    text-align: center;
}
</style>