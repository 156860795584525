<template>
    <v-dialog
      max-width="280"
      persistent
      @input="handleViewDialog"
      :value="show_notification"
    >
        <v-card class="notification-container" v-if="notification && notification.type == 'success'">
            <template>
                <div class="notification-header">
                    <v-icon dark size="60">
                        far fa-check-circle
                    </v-icon>
                </div>
                <div class="notification-content">
                    <div class="notification-title">
                        <span >Éxito!</span>
                    </div>
                    <div class="notification-message">
                        <span>{{ notification.message}}</span>
                    </div>
                    <div class="notification-button">
                        <v-btn dark color="#84cd93" @click="handleCloseNotification">
                            OK
                        </v-btn>
                    </div>                    
                </div>
            </template>            
        </v-card>

        <v-card class="notification-container" v-if="notification && notification.type == 'error'">
            <template>
                <div class="notification-header error">
                    <v-icon dark size="60">
                        far fa-times-circle
                    </v-icon>
                </div>
                <div class="notification-content">
                    <div class="notification-title">
                        <span>Error!</span>
                    </div>
                    <div class="notification-message">
                        <span>{{ notification.message}}</span>
                    </div>
                    <div class="notification-button">
                        <v-btn dark color="#e34c5e" @click="handleCloseNotification">
                            OK
                        </v-btn>
                    </div>                    
                </div>
            </template>            
        </v-card>
    </v-dialog>
</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
    name: 'NotificationSuccess',
    data() {
        return {
            class_title: [
                'title-label'
            ]
        }
    },
    computed: {
        ...mapState('notifications', [
            'notification',
            'show_notification',
            'class_notification'
        ])
    },
    methods: {
        ...mapActions('notifications', [
            'store_notification',
            'can_show_notification',
            'empty_notification'
        ]),
        handleViewDialog(value){
            console.log(value);
        },
        handleCloseNotification(){
            this.empty_notification();
        }
    }
}
</script>

<style scoped>
.v-dialog__content >>> .v-dialog{
    border-radius: 20px !important;
}
.notification-container{
    border-radius: 20px !important;
    font-family: 'Baloo Bhaina 2' !important;
}
.notification-header{
    height: 150px;
    width: 100%;
    background-color: #84cd93;
    border: 2px solid #84cd93;
    border-bottom-left-radius: 50% 50%;
	border-bottom-right-radius: 50% 50%;
    display: flex;
    align-items: center;
    justify-content:center;
}

.notification-content{
    padding: 15px;
    display: flex;
	justify-content: center;
	align-items: center;
    flex-direction: column;
}

.notification-title{
    display: block;
    font-weight: bold;
    font-size: 1.3rem;
    padding-top: 10px;
    padding-bottom: 10px;
}

.notification-message{
    display: block;
    text-align: center;
}

.notification-button{
    padding-top: 15px;
    padding-bottom: 10px;
}

.error{
    background-color: #e34c5e !important;
    border: 2px solid #e34c5e !important;
}
</style>