<template>
    <v-dialog
        v-model="dialog"
        width="350"
        transition="fab-transition"
    >
        <v-card class="pl-0 pr-0">
            <v-card-title>
                <v-icon class="mr-3">fas fa-exchange-alt</v-icon>
                <span class="description-selected">
                    Ingresar a otra sucursal
                </span> 
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pb-0">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete
                                v-model="value_office"
                                :items="offices"
                                hide-details
                                label="Sucursal"
                                outlined
                                :item-text="customText"
                                item-value="id"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-container class="pt-0 pb-0">
                    <v-row>
                        <v-col cols="6">
                            <v-btn dark color="red" block @click="handleCancel">
                                Cancelar
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn dark color="green" block @click="handleSelectOffice">
                                Aceptar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>                    
            </v-card-actions>
        </v-card>

        <v-snackbar
            v-model="show_message_login"            
            color="error"
            :timeout="3000"
            :top="'top'"
        >
            Debe seleccionar una sucursal
            <v-btn
            dark
            text
            @click="show_message_login = false"
            >
                Cerrar
            </v-btn>
        </v-snackbar>
    </v-dialog>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import ArrayTools from '../../helpers/ArrayTools';

export default {
    name: 'SelectOffice',
    data () {
        return {
            dialog: false,
            offices: [],
            value_office: null,
            show_message_login: false,
        }
    },
    computed: {
        ...mapState('authentication', [
            'current_employee',
            'token',
            'select_office',
            'user_companies',
            'user_sedes',
            'user_offices',
            'office_id'
        ])
    },
    methods: {
        ...mapActions('authentication', [
            'set_office_login'
        ]),
        async show(){
            this.clearData();
            await this.verifyCompany();
            this.dialog = !this.dialog;
        },
        clearData () {
            this.value_office = null;
            this.show_message_login = false;
        },
        customText(item){
            let text = item.name
            
            return text
        },
        handleCancel () {
            this.dialog = false;
        },
        async handleSelectOffice() {
            if (this.value_office !== undefined && this.value_office !== null) {
                await this.set_office_login(this.value_office)
                this.clearData();
                this.dialog = false;
            } else {
                this.show_message_login = true;
            }
        },
        handleSelectCompany(value){
            if(value !== undefined && value !== null){
                this.offices = ArrayTools.getOfficesByCompanyId(this.user_offices, value)
                this.removeOfficeLogued();
            } else {
                this.offices = []
            }
        },
        removeOfficeLogued() {
            let index = ArrayTools.getIndexById(this.offices, this.office_id);
            if (index > -1) {
                this.offices.splice(index, 1);
            }
        },
        verifyCompany () {
            if (this.user_companies) {
                if (this.user_companies.length == 1) {
                    this.value_company = this.user_companies[0].id;
                    this.showCompanies = false;
                    this.handleSelectCompany(this.value_company);
                } else {
                    this.showCompanies = true;
                    this.offices = []
                }
            }            
        }
    },
    created () {
        this.verifyCompany();
    }
}
</script>

<style scoped>

</style>