<template>
  <v-app>
    <v-navigation-drawer
      app
      :value="show_menu"
      color="#fff"
      width="208"
      height="100vh"
      class="list-menu"
      @input="handleChangeViewMenu"
    >
      <v-list nav dense>
        <v-list-item>
          <v-list-item-content to="/">
            <v-list-item-title>
              <img src="img/logo_pausa.png" width="100" />
            </v-list-item-title>
          </v-list-item-content>
          <!-- <v-list-item-content>
            <v-list-item-title to="/">
              <img src="img/tomolab-logo.png" width="120" />
            </v-list-item-title>
          </v-list-item-content>
          <v-btn icon @click.stop="mini = !mini">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn> -->
        </v-list-item>
        <v-list-group
          :value="false"
          no-action
          v-if="this.$hasMenu('dashboard')"
          color="purple"
        >
          <template v-slot:activator>
            <i
              class="bx bxs-dashboard bx-sm icon-color"
              id="id_dashboard_icon"
            ></i>
            <label for="" class="text-menu" id="id_dashboard_text"
              >Dashboard</label
            >
            <!-- <v-list-item-title style="">Configuración</v-list-item-title> -->
          </template>

          <v-list-item router to="/app/reportesProductos" class="items-menu">
            <i
              class="bx bx-band-aid bx-sm icon-menu icon-color"
              id="id_reportes_producto_icon"
            ></i>

            <v-list-item-title>Productos</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/app/reportesVentas" class="items-menu">
            <i
              class="bx bx-line-chart bx-sm icon-menu icon-color"
              id="id_comprobantes_icon"
            ></i>
            <v-list-item-title>Ventas</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/app/reportesCompras" class="items-menu">
            <i
              class="bx bx-shopping-bag bx-sm icon-menu icon-color"
              id="id_cuentas_bancarias_icon"
            ></i>
            <v-list-item-title>Compras</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/app/reportesServicios" class="items-menu">
            <i
              class="bx bx-star bx-sm icon-menu icon-color"
              id="id_cuentas_bancarias_icon"
            ></i>
            <v-list-item-title>Servicios</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group
          :value="false"
          no-action
          v-if="verConfiguracion()"
          color="purple"
        >
          <template v-slot:activator>
            <i
              class="bx bx-cog bx-sm icon-color"
              id="id_configuracion_icon"
            ></i>
            <label for="" class="text-menu" id="id_configuracion_text"
              >Configuración</label
            >
            <!-- <v-list-item-title style="">Configuración</v-list-item-title> -->
          </template>

          <v-list-item
            v-if="this.$hasMenu('companies')"
            router
            to="/app/configuracion/empresas"
            class="items-menu"
          >
            <i
              class="bx bx-building-house bx-sm icon-menu icon-color"
              id="id_sucursales_icon"
            ></i>
            <v-list-item-title>Empresas</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="this.$hasMenu('sucursales')"
            router
            to="/app/configuracion/sucursales"
            class="items-menu"
          >
            <i
              class="bx bx-detail bx-sm icon-menu icon-color"
              id="id_comprobantes_icon"
            ></i>
            <v-list-item-title>Sucursales</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="this.$hasMenu('comprobantes')"
            router
            to="/app/configuracion/comprobantes"
            class="items-menu"
          >
            <i
              class="bx bx-detail bx-sm icon-menu icon-color"
              id="id_cuentas_bancarias_icon"
            ></i>
            <v-list-item-title>Comprobantes</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="this.$hasMenu('cuentas-bancarias')"
            router
            to="/app/configuracion/cuentas-bancarias"
            class="items-menu"
          >
            <i
              class="bx bx-data bx-sm icon-menu icon-color"
              id="id_cuentas_bancarias_icon"
            ></i>
            <v-list-item-title>Cuentas Bancarias</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group
          :value="false"
          no-action
          v-if="verRegistros()"
          color="purple"
        >
          <template v-slot:activator>
            <i
              class="bx bx-list-ul bx-sm icon-color"
              id="id_registros_icon"
            ></i>
            <label for="" class="text-menu" id="id_registros_text"
              >Registros</label
            >
            <!-- <v-list-item-title style="">Configuración</v-list-item-title> -->
          </template>

          <v-list-item
            v-if="this.$hasMenu('categorias')"
            router
            to="/app/registros/categorias"
            class="items-menu"
          >
            <i
              class="bx bx-customize bx-sm icon-menu icon-color"
              id="id_sucursales_icon"
            ></i>
            <v-list-item-title>Categorias</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="this.$hasMenu('marcas')"
            router
            to="/app/registros/marcas"
            class="items-menu"
          >
            <i
              class="bx bx-purchase-tag-alt bx-sm icon-menu icon-color"
              id="id_sucursales_icon"
            ></i>
            <v-list-item-title>Marcas</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="this.$hasMenu('unidades')"
            router
            to="/app/registros/unidades"
            class="items-menu"
          >
            <i
              class="bx bx-ruler bx-sm icon-menu icon-color"
              id="id_comprobantes_icon"
            ></i>
            <v-list-item-title>Unidades de medida</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group
          :value="false"
          no-action
          v-if="verLogistica()"
          color="purple"
        >
          <template v-slot:activator>
            <i class="bx bx-car bx-sm icon-color" id="id_logistica_icon"></i>
            <label for="" class="text-menu" id="id_logistica_text"
              >Logística</label
            >
            <!-- <v-list-item-title style="">Configuración</v-list-item-title> -->
          </template>

          <v-list-item
            v-if="this.$hasMenu('areas')"
            router
            to="/app/logistica/areas"
            class="items-menu"
          >
            <i
              class="bx bx-building-house bx-sm icon-menu icon-color"
              id="id_sucursales_icon"
            ></i>
            <v-list-item-title>Áreas</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="this.$hasMenu('proveedores')"
            router
            to="/app/logistica/proveedores"
            class="items-menu"
          >
            <i
              class="bx bx-vector bx-sm icon-menu icon-color"
              id="id_sucursales_icon"
            ></i>
            <v-list-item-title>Proveedores</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="this.$hasMenu('productos')"
            router
            to="/app/logistica/productos"
            class="items-menu"
          >
            <i
              class="bx bx-band-aid bx-sm icon-menu icon-color"
              id="id_comprobantes_icon"
            ></i>
            <v-list-item-title>Productos</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="this.$hasMenu('servicios')"
            router
            to="/app/logistica/servicios"
            class="items-menu"
          >
            <i
              class="bx bx-star bx-sm icon-menu icon-color"
              id="id_comprobantes_icon"
            ></i>
            <v-list-item-title>Servicios</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="this.$hasMenu('compras')"
            router
            to="/app/logistica/compras"
            @click="selectPurchase"
            class="items-menu"
          >
            <i
              class="bx bx-shopping-bag bx-sm icon-menu icon-color"
              id="id_comprobantes_icon"
            ></i>
            <v-list-item-title>Compras</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="this.$hasMenu('almacenes')"
            router
            to="/app/logistica/almacenes"
            class="items-menu"
          >
            <i
              class="bx bx-package bx-sm icon-menu icon-color"
              id="id_comprobantes_icon"
            ></i>
            <v-list-item-title>Almacen</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="this.$hasMenu('almacenes')"
            router
            to="/app/logistica/almacen-insumo"
            class="items-menu"
          >
            <i
              class="bx bx-package bx-sm icon-menu icon-color"
              id="id_comprobantes_icon"
            ></i>
            <v-list-item-title>Almacen Insumos</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="this.$hasMenu('transferencias')"
            router
            to="/app/logistica/transferencias"
            class="items-menu"
          >
            <i
              class="bx bx-send bx-sm icon-menu icon-color"
              id="id_comprobantes_icon"
            ></i>
            <v-list-item-title>Transferencias</v-list-item-title>
          </v-list-item>
          <v-list-item
                      v-if="this.$hasMenu('transferencias')"
                      router
                      to="/app/logistica/insumos"
                      class="items-menu"
                  >
                  <i
              class="bx bx-band-aid bx-sm icon-menu icon-color"
              id="id_comprobantes_icon"
            ></i>
                    <v-list-item-title>Insumos</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                      v-if="this.$hasMenu('transferencias')"
                      router
                      to="/app/logistica/recetas"
                      class="items-menu"
                  >
                  <i
              class="bx bx-vector bx-sm icon-menu icon-color"
              id="id_sucursales_icon"
            ></i>
                    <v-list-item-title>Recetas</v-list-item-title>
                  </v-list-item>
        </v-list-group>

        <v-list-group
          :value="false"
          no-action
          v-if="verVentas()"
          color="purple"
        >
          <template v-slot:activator>
            <i class="bx bx-cart bx-sm icon-color" id="id_ventas_icon"></i>
            <label for="" class="text-menu" id="id_ventas_text">Ventas</label>
            <!-- <v-list-item-title style="">Configuración</v-list-item-title> -->
          </template>

          <v-list-item
            v-if="this.$hasMenu('clientes')"
            router
            to="/app/ventas/clientes"
            class="items-menu"
          >
            <i
              class="bx bx-user bx-sm icon-menu icon-color"
              id="id_sucursales_icon"
            ></i>
            <v-list-item-title>Clientes</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="this.$hasMenu('ventas')"
            router
            to="/app/ventas/nueva-venta"
            class="items-menu"
          >
            <i
              class="bx bx-layer-plus bx-sm icon-menu icon-color"
              id="id_sucursales_icon"
            ></i>
            <v-list-item-title>Punto de venta</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="this.$hasMenu('orden-de-servicio')"
            router
            to="/app/ventas/orden-de-servicio"
            class="items-menu"
          >
            <i
              class="bx bx-detail bx-sm icon-menu icon-color"
              id="id_comprobantes_icon"
            ></i>
            <v-list-item-title>Orden de servicio</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="this.$hasMenu('ventas')"
            router
            to="/app/ventas/pedidos"
            class="items-menu"
          >
            <i
              class="bx bx-layer-plus bx-sm icon-menu icon-color"
              id="id_sucursales_icon"
            ></i>
            <v-list-item-title>Pedidos</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group
          :value="false"
          no-action
          v-if="verCuentas()"
          color="purple"
        >
          <template v-slot:activator>
            <i class="bx bx-data bx-sm icon-color" id="id_cuentas_icon"></i>
            <label for="" class="text-menu" id="id_cuentas_text">Cuentas</label>
            <!-- <v-list-item-title style="">Configuración</v-list-item-title> -->
          </template>

          <v-list-item
            v-if="this.$hasMenu('cuentas-cobrar')"
            router
            to="/app/cuentas/cuentas-por-cobrar"
            class="items-menu"
          >
            <i
              class="bx bx-bookmark-alt-plus bx-sm icon-menu icon-color"
              id="id_sucursales_icon"
            ></i>
            <v-list-item-title>Cuentas por cobrar</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="this.$hasMenu('cuentas-pagar')"
            router
            to="/app/cuentas/cuentas-por-pagar"
            class="items-menu"
          >
            <i
              class="bx bx-bookmark-alt-minus bx-sm icon-menu icon-color"
              id="id_sucursales_icon"
            ></i>
            <v-list-item-title>Cuentas por pagar</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="false" no-action v-if="verCaja()" color="purple">
          <template v-slot:activator>
            <i
              class="bx bx-cart-download bx-sm icon-color"
              id="id_caja_icon"
            ></i>
            <label for="" class="text-menu" id="id_caja_text">Caja</label>
            <!-- <v-list-item-title style="">Configuración</v-list-item-title> -->
          </template>

          <v-list-item
            v-if="this.$hasMenu('cajas')"
            router
            to="/app/cajas/cajas-registradoras"
            class="items-menu"
          >
            <i
              class="bx bx-cart-add bx-sm icon-menu icon-color"
              id="id_sucursales_icon"
            ></i>
            <v-list-item-title>Cajas Registradas</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="this.$hasMenu('movimientos')"
            router
            to="/app/cajas/movimientos"
            class="items-menu"
          >
            <i
              class="bx bx-git-compare bx-sm icon-menu icon-color"
              id="id_sucursales_icon"
            ></i>
            <v-list-item-title>Movimientos</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group
          :value="false"
          no-action
          v-if="verComisiones()"
          color="purple"
        >
          <template v-slot:activator>
            <i
              class="bx bx-package bx-sm icon-color"
              id="id_comisiones_icon"
            ></i>
            <label for="" class="text-menu" id="id_comisiones_text"
              >Comisiones</label
            >
            <!-- <v-list-item-title style="">Configuración</v-list-item-title> -->
          </template>

          <v-list-item
            v-if="this.$hasMenu('procesar-comisiones')"
            router
            to="/app/comisiones/procesar-comisiones"
            class="items-menu"
          >
            <i
              class="bx bx-dollar bx-sm icon-menu icon-color"
              id="id_sucursales_icon"
            ></i>
            <v-list-item-title>Procesar comisiones</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="this.$hasMenu('comisiones-procesadas')"
            router
            to="/app/comisiones/comisiones-procesadas"
            class="items-menu"
          >
            <i
              class="bx bx-dollar bx-sm icon-menu icon-color"
              id="id_sucursales_icon"
            ></i>
            <v-list-item-title>Comisiones procesadas</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group
          :value="false"
          no-action
          v-if="verSeguridad()"
          color="purple"
        >
          <template v-slot:activator>
            <i
              class="bx bx-shield-alt-2 bx-sm icon-color"
              id="id_seguridad_icon"
            ></i>
            <label for="" class="text-menu" id="id_seguridad_text"
              >Seguridad</label
            >
            <!-- <v-list-item-title style="">Configuración</v-list-item-title> -->
          </template>

          <v-list-item
            v-if="this.$hasMenu('empleados')"
            router
            to="/app/seguridad/empleados"
            class="items-menu"
          >
            <i
              class="bx bx-user-plus bx-sm icon-menu icon-color"
              id="id_sucursales_icon"
            ></i>
            <v-list-item-title>Empleados</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="this.$hasMenu('roles')"
            router
            to="/app/seguridad/roles"
            class="items-menu"
          >
            <i
              class="bx bx-group bx-sm icon-menu icon-color"
              id="id_sucursales_icon"
            ></i>
            <v-list-item-title>Roles de usuarios</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group
          :value="false"
          no-action
          v-if="verReportes()"
          color="purple"
        >
          <template v-slot:activator>
            <i class="bx bx-receipt bx-sm icon-color" id="id_reportes_icon"></i>
            <label for="" class="text-menu" id="id_reportes_text"
              >Reportes</label
            >
            <!-- <v-list-item-title style="">Configuración</v-list-item-title> -->
          </template>

          <v-list-item
            v-if="this.$hasMenu('reporte-compras')"
            router
            to="/app/reportes/reporte-compras"
            class="items-menu"
          >
            <i
              class="bx bx-money-withdraw bx-sm icon-menu icon-color"
              id="id_sucursales_icon"
            ></i>
            <v-list-item-title>Compras</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="this.$hasMenu('reporte-ventas')"
            router
            to="/app/reportes/reporte-ventas"
            class="items-menu"
          >
            <i
              class="bx bx-line-chart bx-sm icon-menu icon-color"
              id="id_sucursales_icon"
            ></i>
            <v-list-item-title>Ventas</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="this.$hasMenu('reporte-historial-cajas')"
            router
            to="/app/reportes/reporte-caja"
            class="items-menu"
          >
            <i
              class="bx bx-history bx-sm icon-menu icon-color"
              id="id_sucursales_icon"
            ></i>
            <v-list-item-title>Historial de cajas</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="this.$hasMenu('reporte-transferencias')"
            router
            to="/app/reportes/reporte-transferencias"
            class="items-menu"
          >
            <i
              class="bx bx-send bx-sm icon-menu icon-color"
              id="id_sucursales_icon"
            ></i>
            <v-list-item-title>Transferencias</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="this.$hasMenu('reporte-consumo-interno')"
            router
            to="/app/reportes/consumo-interno"
            class="items-menu"
          >
            <i
              class="bx bx-detail bx-sm icon-menu icon-color"
              id="id_sucursales_icon"
            ></i>
            <v-list-item-title>Consumo Interno</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="this.$hasMenu('reporte-merma')"
            router
            to="/app/reportes/merma"
            class="items-menu"
          >
            <i
              class="bx bx-line-chart-down bx-sm icon-menu icon-color"
              id="id_sucursales_icon"
            ></i>
            <v-list-item-title>Merma</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="this.$hasMenu('reporte-productos-vendidos')"
            router
            to="/app/reportes/productos-vendidos"
            class="items-menu"
          >
            <i
              class="bx bx-purchase-tag-alt bx-sm icon-menu icon-color"
              id="id_sucursales_icon"
            ></i>
            <v-list-item-title>Productos Vendidos</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="this.$hasMenu('reporte-servicios-atendidos')"
            router
            to="/app/reportes/servicios-atendidos"
            class="items-menu"
          >
            <i
              class="bx bx-star bx-sm icon-menu icon-color"
              id="id_sucursales_icon"
            ></i>
            <v-list-item-title>Servicios Atendidos</v-list-item-title>
          </v-list-item>
        </v-list-group>

      </v-list>
      <!--<template v-slot:append>
        <div class="pa-2">
          <v-speed-dial
              :bottom="true"
              :right="true"
              :direction="'top'"
              :open-on-hover="true"
              :transition="'slide-y-transition'"
              class="dial-logout"
          >
            <template v-slot:activator>
              <v-btn color="blue darken-2" dark fab class="btn-logout">
                <v-icon size="22">mdi-account-circle</v-icon>
              </v-btn>
            </template>
            <v-btn fab dark small color="red" @click="handleLogout">
              <v-icon size="16">fas fa-sign-out-alt</v-icon>
            </v-btn>
            <v-btn
                v-if="locked"
                fab
                dark
                small
                color="green"
                @click="handleUnLockSystem"
            >
              <v-icon size="16">fas fa-lock-open</v-icon>
            </v-btn>
            <v-btn
                v-else
                fab
                dark
                small
                color="amber"
                @click="handleLockSystem"
            >
              <v-icon size="16">fas fa-lock</v-icon>
            </v-btn>
            <v-btn
                v-if="user_offices.length > 1"
                fab
                dark
                small
                color="blue"
                @click="handleChangeOfficeLogin"
            >
              <v-icon size="16">fas fa-exchange-alt</v-icon>
            </v-btn>
          </v-speed-dial>
        </div>
      </template>-->
    
      <template v-slot:append>
        <div
          :class="mini ? 'mini-class' : ''"
          style="
            display: flex;
            justify-content: space-around;
            padding: 4px 10px 14px 10px;
          "
        >
          <v-btn fab dark small color="red" @click="handleLogout">
            <v-icon size="16">fas fa-sign-out-alt</v-icon>
          </v-btn>
          <v-btn
            v-if="locked"
            fab
            dark
            small
            color="green"
            @click="handleUnLockSystem"
          >
            <v-icon size="16">fas fa-lock-open</v-icon>
          </v-btn>
          <v-btn v-else fab dark small color="amber" @click="handleLockSystem">
            <v-icon size="16">fas fa-lock</v-icon>
          </v-btn>
          <v-btn
            v-if="user_offices.length > 1"
            fab
            dark
            small
            color="blue"
            @click="handleChangeOfficeLogin"
          >
            <v-icon size="16">fas fa-exchange-alt</v-icon>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    
    <v-content class="main-content">
      <router-view></router-view>
    </v-content>

    <Locked ref="locked"></Locked>
    <notification-success></notification-success>
    <select-office ref="selectOffice"></select-office>
  </v-app>
</template>

<script>
import {mapState, mapActions} from "vuex";

import Locked from "../components/auth/Locked";
import NotificationSuccess from "../components/notifications/NotificationSuccess";
import SelectOffice from "../components/auth/SelectOffice";

export default {
  name: "MainLayout",
  components: {
    Locked,
    NotificationSuccess,
    SelectOffice,
  },
  data() {
    return {
      data: [
        {
          id: 0,
          id_html: "id_dashboard_icon",
          id_html_text: "id_dashboard_text",
          active: false,
          route: "",
          detail: [
            {
              id: 1,
              id_html: "id_reportes_producto_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "reportesProductos",
            },
            {
              id: 2,
              id_html: "id_comprobantes_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "comprobantes",
            },
            {
              id: 3,
              id_html: "id_cuentas_bancarias_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "cuentas-bancarias",
            },
          ],
        },
        {
          id: 1,
          id_html: "id_configuracion_icon",
          id_html_text: "id_configuracion_text",
          active: false,
          route: "configuracion",
          detail: [
            {
              id: 1,
              id_html: "id_sucursales_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "sucursales",
            },
            {
              id: 2,
              id_html: "id_comprobantes_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "comprobantes",
            },
            {
              id: 3,
              id_html: "id_cuentas_bancarias_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "cuentas-bancarias",
            },
          ],
        },
        {
          id: 2,
          id_html: "id_registros_icon",
          id_html_text: "id_registros_text",
          active: false,
          route: "registros",
          detail: [
            {
              id: 1,
              id_html: "id_categorias_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "categorias",
            },
            {
              id: 2,
              id_html: "id_marcas_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "marcas",
            },
            {
              id: 3,
              id_html: "id_unidades_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "unidades",
            },
          ],
        },
        {
          id: 3,
          id_html: "id_logistica_icon",
          id_html_text: "id_logistica_text",
          active: false,
          route: "logistica",
          detail: [
            {
              id: 1,
              id_html: "id_proveedores_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "proveedores",
            },
            {
              id: 2,
              id_html: "id_compras_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "compras",
            },
            {
              id: 3,
              id_html: "id_almacenes_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "almacenes",
            },
            {
              id: 4,
              id_html: "id_almacenesReactivos_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "almacenesReactivos",
            },
            {
              id: 5,
              id_html: "id_transferencias_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "transferencias",
            },
          ],
        },
        {
          id: 4,
          id_html: "id_laboratorio_icon",
          id_html_text: "id_laboratorio_text",
          active: false,
          route: "laboratorio",
          detail: [
            {
              id: 1,
              id_html: "id_especialidad_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "especialidad",
            },
            {
              id: 2,
              id_html: "id_material_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "material",
            },
            {
              id: 3,
              id_html: "id_reactive_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "reactive",
            },
            {
              id: 4,
              id_html: "id_analisis_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "analisis",
            },
            {
              id: 5,
              id_html: "id_procesamiento_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "procesamiento",
            },
            {
              id: 6,
              id_html: "id_report_informar_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "report",
            },
          ],
        },
        {
          id: 5,
          id_html: "id_tesoreria_icon",
          id_html_text: "id_tesoreria_text",
          active: false,
          route: "tesoreria",
          detail: [
            {
              id: 1,
              id_html: "id_comisionMedicos_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "comisionMedicos",
            },
          ],
        },
        {
          id: 6,
          id_html: "id_ventas_icon",
          id_html_text: "id_ventas_text",
          active: false,
          route: "ventas",
          detail: [
            {
              id: 1,
              id_html: "id_clientes_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "clientes",
            },
            {
              id: 2,
              id_html: "id_pacientes_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "pacientes",
            },
            {
              id: 3,
              id_html: "id_doctores_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "doctores",
            },
            {
              id: 4,
              id_html: "id_empresas_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "empresas",
            },
            {
              id: 5,
              id_html: "id_solicitud_analisis_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "solicitud-analisis",
            },
            {
              id: 6,
              id_html: "id_cotizaciones_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "cotizaciones",
            },
          ],
        },
        {
          id: 7,
          id_html: "id_cuentas_icon",
          id_html_text: "id_cuentas_text",
          active: false,
          route: "cuentas",
          detail: [
            {
              id: 1,
              id_html: "id_cuentas_por_cobrar_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "cuentas-por-cobrar",
            },
            {
              id: 2,
              id_html: "id_cuentas_por_pagar_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "cuentas-por-pagar",
            },
          ],
        },
        {
          id: 8,
          id_html: "id_caja_icon",
          id_html_text: "id_caja_text",
          active: false,
          route: "cajas",
          detail: [
            {
              id: 1,
              id_html: "id_cajas_registradoras_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "cajas-registradoras",
            },
            {
              id: 2,
              id_html: "id_movimientos_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "movimientos",
            },
          ],
        },
        {
          id: 9,
          id_html: "id_planilla_icon",
          id_html_text: "id_planilla_text",
          active: false,
          route: "planillas",
          detail: [
            {
              id: 1,
              id_html: "id_honorarios_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "Honorarios",
            },
            {
              id: 2,
              id_html: "id_planillas_info_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "planillas-Info",
            },
            {
              id: 3,
              id_html: "id_bonos_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "bonos",
            },
            {
              id: 4,
              id_html: "id_regimen_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "Regimen",
            },
            {
              id: 5,
              id_html: "id_seguros_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "Seguros",
            },
            {
              id: 6,
              id_html: "id_puestos_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "Puestos",
            },
          ],
        },
        {
          id: 10,
          id_html: "id_seguridad_icon",
          id_html_text: "id_seguridad_text",
          active: false,
          route: "seguridad",
          detail: [
            {
              id: 1,
              id_html: "id_empleados_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "empleados",
            },
            {
              id: 2,
              id_html: "id_roles_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "roles",
            },
          ],
        },
        {
          id: 11,
          id_html: "id_reporte_icon",
          id_html_text: "id_reporte_text",
          active: false,
          route: "reportes",
          detail: [
            {
              id: 1,
              id_html: "id_reporte_compras_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "reporte-compras",
            },
            {
              id: 2,
              id_html: "id_reporte_ventas_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "reporte-ventas",
            },
            {
              id: 3,
              id_html: "id_reporte_ventas_tarjeta_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "reporte-ventas-tarjeta",
            },
            {
              id: 4,
              id_html: "id_reporte_caja_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "reporte-caja",
            },
            {
              id: 5,
              id_html: "id_reporte_transferencia_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "reporte-transferencias",
            },
            {
              id: 6,
              id_html: "id_consumo_interno_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "consumo-interno",
            },
            {
              id: 7,
              id_html: "id_merma_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "merma",
            },
            {
              id: 8,
              id_html: "id_productos_vendidos_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "productos-vendidos",
            },
            {
              id: 9,
              id_html: "id_servicios_atendidos_icon",
              id_html_text: "id_configuracion_text",
              active: false,
              route: "servicios-atendidos",
            },
          ],
        },
      ],
      mini: false,
    };
  },
  computed: {
    ...mapState("authentication", [
      "show_menu",
      "token",
      "select_office",
      "locked",
      "user_offices",
    ]),
  },
  watch: {
    $route(to, from) {
      this.data = this.data.map((item) => {
        item.action = this.$route.path.includes(item.route);
        if (item.detail.length > 0) {
          item.detail = item.detail.map((subItem) => {
            subItem.action = this.$route.path.includes(subItem.route);
            return subItem;
          });
        }
        return item;
      });
      this.data.map((item) => {
        const elemento = document.getElementById(item.id_html);
        const elemento_text = document.getElementById(item.id_html_text);
        if (item.action) {
          elemento && elemento.classList.add("active");
          elemento_text && elemento_text.classList.add("active");
        } else {
          elemento && elemento.classList.remove("active");
          elemento_text && elemento_text.classList.remove("active");
        }
        item.detail.map((subItem) => {
          const elementChildren = document.getElementById(subItem.id_html);
          if (subItem.action) {
            elementChildren && elementChildren.classList.add("active");
          } else {
            elementChildren && elementChildren.classList.remove("active");
          }
        });
      });
    },
  },
  methods: {
    ...mapActions("authentication", [
      "logout",
      "run_set_locked",
      "lockSystem",
      "setShowMenu",
    ]),
    ...mapActions("purchases", ["clean_data", "setPurchaseDetail"]),
    handleChangeViewMenu(value) {
      this.setShowMenu(value);
    },
    selectMenu() {
      console.log("seleccionar menu");
    },
    selectPurchase() {
      this.clean_data();
      this.setPurchaseDetail([]);
    },
    handleLogout() {
      this.logout();
    },
    handleLockSystem() {
      this.run_set_locked(true);
      this.$refs.locked.show();
      //await this.lockSystem();
    },
    handleUnLockSystem() {
      this.run_set_locked(false);
    },
    verConfiguracion() {
      return (
          this.$hasMenu("companies") ||
          this.$hasMenu("sedes") ||
          this.$hasMenu("sucursales") ||
          this.$hasMenu("comprobantes") ||
          this.$hasMenu("cuentas-bancarias")
      );
    },
    verRegistros() {
      return (
          this.$hasMenu("categorias") ||
          this.$hasMenu("capacidades") ||
          this.$hasMenu("colores") ||
          this.$hasMenu("marcas") ||
          this.$hasMenu("tipos") ||
          this.$hasMenu("unidades")
      );
    },
    verLogistica() {
      return (
          this.$hasMenu("proveedores") ||
          this.$hasMenu("productos") ||
          this.$hasMenu("servicios") ||
          this.$hasMenu("compras") ||
          this.$hasMenu("almacenes") ||
          this.$hasMenu("transferencias") ||
          this.$hasMenu("areas")
      );
    },
    verVentas() {
      return (
          this.$hasMenu("clientes") ||
          this.$hasMenu("ventas") ||
          this.$hasMenu("nuevo-pedido") ||
          this.$hasMenu("pedidos") ||
          this.$hasMenu("orden-de-servicio")
      );
    },
    verCaja() {
      return this.$hasMenu("cajas") || this.$hasMenu("movimientos");
    },
    verComisiones() {
      return (
          this.$hasMenu("procesar-comisiones") ||
          this.$hasMenu("comisiones-procesadas")
      );
    },
    verSeguridad() {
      return this.$hasMenu("empleados") || this.$hasMenu("roles");
    },
    verReportes() {
      return (
          this.$hasMenu("reporte-compras") ||
          this.$hasMenu("reporte-ventas") ||
          this.$hasMenu("reporte-transferencias") ||
          this.$hasMenu("reporte-historial-cajas") ||
          this.$hasMenu("reporte-nota-credito") ||
          this.$hasMenu("sesiones-vendedores") ||
          this.$hasMenu("reporte-consumo-interno") ||
          this.$hasMenu("reporte-merma") ||
          this.$hasMenu("reporte-productos-vendidos") ||
          this.$hasMenu("reporte-servicios-atendidos")
      );
    },
    verCuentas() {
      return this.$hasMenu("cuentas-cobrar") || this.$hasMenu("cuentas-pagar");
    },
    handleChangeOfficeLogin() {
      this.$refs.selectOffice.show();
    },
  },
};
</script>

<!--<style>
.v-list-item__content{text-align: left}
.v-btn:not(.v-btn--round).v-size--default {
  padding: 0 10px !important;
  /*width: 100%;*/
}
.border {
  border-left: 4px solid #616161;
}

.icon-menu {
  margin-right: 5px !important;
}

.sub-menu {
  margin-left: 30px;
  margin-right: 0px;
}


.main-content {
  text-align: left !important;
  background-color: #f4f6f8;
}

.list-menu {
  text-align: center;
}

.dial-logout {
  right: 2px !important;
  bottom: 5px !important;
}

.btn-logout {
  height: 40px !important;
  width: 40px !important;
}

.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 0px !important;
}
</style>-->
<style>
.v-list-item__icon {
  justify-content: end !important;
  width: 100% !important;
}

.border {
  border-left: 4px solid #616161;
}

.text-menu {
  cursor: pointer;
  margin-left: 6px;
  align-items: start !important;
  color: #666666;
}

.items-menu {
  padding-left: 30px !important;
  text-align: start;
}

.icon-menu {
  margin-right: 5px !important;
}

.sub-menu {
  margin-left: 30px;
  margin-right: 0px;
}

.w-item-menu {
  padding: 0 3px !important;
}

.main-content {
  text-align: left !important;
  background-color: #f4f6f8;
}

.list-menu {
  text-align: center;
}

.dial-logout {
  right: 2px !important;
  bottom: 5px !important;
}

::-webkit-scrollbar {
  display: none;
}

.btn-logout {
  height: 40px !important;
  width: 40px !important;
}
</style>